//
import React from 'react'
import classnames from 'classnames'
import shqLogoSvg from 'assets/images/frontend/shq-logo-w-v2.svg'

/**
 * Backdrop Properties
 */

const shqlogo = {
    /* $FlowFixMe */
    url: shqLogoSvg,
    alt: 'ShipperHQ',
}

export default function Backdrop(props) {
    return (
        <div className={classnames('lt-shq-bg', props.className)}>
            <a href="/" title="ShipperHQ Home">
                <img src={shqlogo.url} alt={shqlogo.alt} className="shq-logo" />
            </a>
            {props.children}
        </div>
    )
}
